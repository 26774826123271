import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className="relative bg-gray-100 min-h-screen flex flex-col items-center py-10 mt-20 overflow-hidden">
      {/* Background SVG Blobs */}
      <div className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px]">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
          <path fill="#FBD7E3" d="M44.4,-65.5C55.4,-57.7,59.9,-41.5,65.6,-25.9C71.3,-10.3,78.1,5,76.5,19.6C75,34.2,65.2,47.9,53.1,58.1C41,68.3,26.6,74.8,12.3,73.8C-2,72.8,-16.4,64.4,-31.2,57.4C-46,50.5,-61.2,45.1,-66.4,36.3C-71.6,27.6,-66.8,15.5,-66.7,3.1C-66.7,-9.3,-71.4,-21.9,-66.5,-30.4C-61.6,-39,-47.1,-43.5,-34.3,-51.7C-21.5,-59.9,-10.8,-71.8,3.1,-76.4C17.1,-81,34.1,-78.1,44.4,-65.5Z" transform="translate(100 100)" />
        </svg>
      </div>

      <div className="absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 w-[500px] h-[500px]">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
          <path fill="#FBD7E3" d="M40.4,-64.2C54.4,-57.8,65.7,-45.8,71.7,-31.4C77.8,-17,78.6,-0.2,75.4,15.3C72.1,30.8,64.9,45,54.1,53.5C43.2,62.1,28.6,64.9,13.5,65.5C-1.7,66.1,-17.2,64.4,-33.3,58.6C-49.4,52.8,-66,42.9,-73.7,28.9C-81.3,14.9,-80.1,-3.2,-73.3,-17.7C-66.6,-32.2,-54.3,-42.9,-41.4,-49.8C-28.5,-56.8,-14.2,-59.9,0.8,-60.8C15.8,-61.8,31.5,-60.6,40.4,-64.2Z" transform="translate(100 100)" />
        </svg>
      </div>

      {/* Privacy Policy Content */}
      <div className="max-w-5xl text-left bg-white shadow-xl rounded-2xl p-10 relative z-10 border border-gray-300">
        <h1 className="text-4xl font-bold text-pink-700 mb-8 text-center">Privacy Policy</h1>
        <div className="space-y-8 text-gray-700 leading-relaxed">
          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Introduction</h2>
            <p>
              This Privacy Policy describes how Maheshwari Innovative IT Services LLP collects, uses, shares, protects, and processes your personal data through our website and related services. We comply with applicable data protection laws, including the General Data Protection Regulation (GDPR) and the Information Technology Act, 2000 (India).
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Collection of Information</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li><b>Personal Information:</b> Name, date of birth, address, telephone/mobile number, email ID, and identity/address proofs.</li>
              <li><b>Sensitive Data:</b> Payment instrument details or biometric information (only with explicit user consent).</li>
              <li><b>Usage Data:</b> Behavioral, transactional, and preference data tracked during platform usage.</li>
            </ul>
            <p className="mt-2">
              We collect data directly from users, automatically via cookies/device logs, and through third parties. Users under 18 are not permitted to use our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Usage of Information</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li>Provide services requested by users.</li>
              <li>Improve user experience and resolve disputes.</li>
              <li>Conduct marketing research, prevent fraud, and ensure legal compliance.</li>
              <li>Enhance security and maintain platform integrity.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Sharing of Information</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li>With Group Entities & Business Partners: For platform operations, service improvements, and analytics.</li>
              <li>With Third-Party Service Providers: For logistics, payment processing, security, analytics, and fraud detection.</li>
              <li>With Law Enforcement Agencies: When required by law or to protect users' rights and safety.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Security Precautions</h2>
            <p>
              We implement industry-standard security measures, including encryption (AES, TLS), secure access protocols (MFA, RBAC), and regular security audits. However, users are responsible for protecting their account credentials.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Data Deletion and Retention</h2>
            <p>
              Users can delete their account via platform settings. Certain data may be retained as required by law, for fraud prevention, or for legitimate operational purposes. Anonymized data may be used for analytics.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Your Rights</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li>Access, update, or rectify your personal data.</li>
              <li>Withdraw consent for data usage (withdrawal may impact services).</li>
              <li>Request data deletion (subject to legal and operational constraints).</li>
              <li>Report misuse or privacy concerns.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Consent</h2>
            <p>
              By using our platform, you consent to the collection, use, and sharing of your data as described in this Privacy Policy.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Changes to the Privacy Policy</h2>
            <p>
              We may update this Privacy Policy periodically. Users are encouraged to review this policy regularly for any changes.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Safety Standards & Protection Against CSAE</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li><b>Zero Tolerance Policy:</b> Strict prohibition of any content, communication, or behavior related to child exploitation or abuse.</li>
              <li><b>Proactive Content Monitoring:</b> AI tools + human moderation to detect and remove harmful content.</li>
              <li><b>User Reporting:</b> Users can report violations. Compliance team acts immediately and confidentially.</li>
              <li><b>Collaboration with Authorities:</b> Full cooperation with law enforcement and child protection agencies.</li>
              <li><b>Strict Enforcement:</b> Violators face permanent bans and legal action.</li>
              <li><b>Compliance:</b> Aligns with Google Play CSAE policies and international laws.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Contact & Support</h2>
            <p>
              If you come across any content or activity that violates our policies, please contact us immediately through our official communication channels.
            </p>
          </section>

          <p className="text-sm text-gray-500 mt-10">Last Updated: March 13, 2025</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
